<template>
  <div class="evaluate-home">
    <div class="header">
      <el-button
        type="primary"
        class="common-button commonBtn"
        icon="iconfont icon-rusuodengji"
        @click="addEvaluate"
      >
        新建评价
      </el-button>
    </div>
    <div class="block" v-loading="loading">
      <el-table
        :data="evaluateList"
        :header-cell-style="{ padding: 0 }"
        style="width: 100%; height: 100%; border: none"
        border
      >
        <el-table-column
          width="220"
          prop="opinionTime"
          label="评价日期"
          align="center"
        ></el-table-column>
        <el-table-column prop="ote" label="总体评价" align="center"></el-table-column>
        <el-table-column prop="memo" label="说明" align="center"></el-table-column>
        <el-table-column
          width="150"
          prop="recordName"
          label="记录人"
          align="center"
        ></el-table-column>
        <el-table-column fixed="right" label="操作" align="center" width="100">
          <template slot-scope="scope">
            <el-button @click="checkPlan(scope.row)" type="text" size="small">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <opinion-dialog ref="opinionDialog" @save="dialogSave" :info="info"></opinion-dialog>
  </div>
</template>

<script>
import OpinionDialog from './components/OpinionDialog.vue'
export default {
  name: 'solutionEvaluate',
  components: {
    OpinionDialog
  },
  props: {
    info: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      loading: false,
      evaluateList: []
    }
  },
  created() {
    this.getEvaluateList()
  },
  mounted() {},
  methods: {
    formarNum(v) {
      if (v === null) {
        return ''
      }
      if (v === 0) {
        return 0
      }
      if (String(v).includes('-')) {
        return v
      } else {
        return '+' + v
      }
    },
    getEvaluateList() {
      this.$api.get(`/v1/webconsole/solution/show/opinionList/${this.info.id}`).then(
        (res) => {
          if (res.data && res.data.data) {
            this.evaluateList = res.data.data
          }
          this.loading = false
        },
        () => {
          this.loading = false
        }
      )
    },
    addEvaluate() {
      sessionStorage.setItem('solutionId', this.info.id)
      this.$refs.opinionDialog.Show('add')
    },
    checkPlan(val) {
      this.setItem(val)
    },

    dialogSave() {
      this.loading = true
      this.evaluateList = []
      setTimeout(() => {
        this.getEvaluateList()
      }, 500)
    },
    setItem(val) {
      this.$refs.opinionDialog.Show('edit', val)
    }
  }
}
</script>

<style scoped lang="less">
.evaluate-home {
  display: flex;
  flex-direction: column;
  height: 100%;
  .header {
    height: 3rem;
    background: #fff;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ccc;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
}
.block {
  flex: 1;
  overflow: auto;
  // padding-top: 20px;
  background-color: #f6f6f6;
  .plan {
    margin-left: 100px;
    height: 270px;
    // width: 100%;
    display: flex;
    // background: pink;
    .plan-left {
      height: 100%;
      width: 100px;

      .plan-time {
        width: 98px;
        height: 50px;
        background: #3879af;
        border-radius: 10px;
        color: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: center;
        .plan-time-above {
          height: 50%;
          // line-height: 25px;
          font-weight: 700;
          font-size: 18px;
        }
        .plan-time-below {
          height: 50%;
          // line-height: 25px;
        }
      }
      .plan-column {
        height: 50px;
        .column {
          width: 4px;
          height: 250px;
          background: #75b1bc;
          display: inline-block;
          vertical-align: top;
          margin-left: 48px;
        }
      }
    }
    .plan-right {
      margin-left: 30px;
      height: 250px;
      width: 40%;
      background: #ffffff;
      display: inline-block;
      border-radius: 8px;
      .block-header {
        font-size: 20px;
        font-weight: 700;
        margin: 10px 0px 15px 10px;
      }
      .block-treat {
        margin-left: 10px;
        margin-bottom: 15px;
        .name {
          font-size: 16px;
          font-weight: 800;
        }
      }
      .button {
        margin-left: 10px;
      }
    }
  }
}
</style>
